import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { Navigate, useNavigate } from 'react-router-dom';
import Header from '../header/Header';

import './thanks.css'

import { storage } from '../firebase';
import { listAll, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const PurchaseThanks = () => {
  const [url, setUrl] = useState("");

  const params = useParams();
  const navigate = useNavigate();
  let formattedProduct = params.product.split('_').join(' ');

  formattedProduct = formattedProduct.split(' ').map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');

  const name = ' ' + formattedProduct;

  useEffect(() => {
    fetchDownloadUrl();
  }, []);

  const fetchDownloadUrl = async () => {
    const downloadUrl = await getDownloadURL(ref(storage, "Aurora.pkg"))
    setUrl(downloadUrl);
  };


  return (
    <div className='thanks__container'>

      <div className="salepage__img">
        {/* <img src={bgImg} alt="img" className='salepage__imgsize' />*/}
      </div>

      <Header />
      <div className="thanks__ontop">
        <div className="thanks__title">
          Thanks
          for purchasing
          {name}
        </div>

        <div className="thanks__show">
          Here are your downloads
        </div>


        <div className="thanks__downloads">
          <div className="thanks__downloadbox">
            Aurora <br /> Mac
            <div className="thanks__downloadunderlined">
              <a href={url} download>Download File</a>

            </div>
          </div>


          <div className="thanks__downloadbox">
            Aurora Windows
            <br />
            Coming Soon
          </div>
        </div>
      </div>
    </div>
  )
}

export default PurchaseThanks