import React, { useEffect, useState } from 'react'
import bgImg from '../assets/AccountGradientBG.png'
import './signup.css'
import { motion } from "framer-motion"

import { UserAuth } from '../context/AuthContext'
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth'

import { db } from "../firebase"
import {
    doc,
    collection,
    onSnapshot,
    setDoc,
    deleteDoc,
    query,
    where,
    getDocs,
    orderBy,
    serverTimestamp
} from "firebase/firestore"

import { Link, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'

const SignUp = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [name, setName] = useState('')
    const [buttonVisible, setButtonVisible] = useState(false);
    const [mouseEnter, setMouseEnter] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [subscribeNewsletter, setSubscribeNewsletter] = useState(false);
    const navigate = useNavigate()

    const auth = getAuth();
    const { createUser } = UserAuth()
    const { verifyEmail } = UserAuth()
    const params = useParams();

    const { user } = UserAuth();

    const usersCollectionRef = collection(db, "users");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            await createUser(email, password);
            createUserAndNavigate();
        } catch (error) {
            let friendlyMessage;
            switch (error.code) {
                case 'auth/email-already-in-use':
                    friendlyMessage = "This email is already registered. Please use a different email or sign in.";
                    break;
                case 'auth/invalid-email':
                    friendlyMessage = "Oops, that doesn't look like a valid email address.";
                    break;
                case 'auth/weak-password':
                    friendlyMessage = "Your password should be stronger. Please use a mix of letters, numbers, and symbols.";
                    break;
                default:
                    friendlyMessage = "Something went wrong. Please try again later.";
                    break;
            }
            setError(friendlyMessage);
        }
    };

    const handleSendVerificationEmail = async () => {
        const currentUser = getAuth().currentUser;
        if (currentUser) {
            try {
                await verifyEmail();
            } catch (error) {
                console.error("Error sending verification email: ", error);
            }
        }
    };

    useEffect(() => {
        if (password && email && email.includes('@') && acceptTerms) {
            setButtonVisible(true);
        }
        else {
            setButtonVisible(false);
        }
    }, [email, password, acceptTerms])

    const createUserAndNavigate = async () => {
        const newUser = {
            email: email,
            subscribeNewsletter: subscribeNewsletter
        };

        await setDoc(doc(usersCollectionRef), newUser);

        await handleSendVerificationEmail();



        if (params.navigation === "iceland") {
            navigate('/checkout/iceland');
        }
        else {
            navigate(`/aurora`)
        }
    };

    return (
        <div className='signup__container'>
            <div className="salepage__img">
                <img src={bgImg} alt="img" className='salepage__imgsize' />
            </div>

            <div className="salepage__ontop">
                <div className="signup__gap"></div>
                <motion.div className="signup__infocontainer"
                    initial={{ opacity: 0, scale: 0.7 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true }}>
                    <div className='signup__headertext'>
                        Create A New Account
                    </div>
                    <form className="signup__inputcontainer" onSubmit={handleSubmit}>
                        <input className='signup__input'
                            type="email"
                            placeholder='Email'
                            onChange={(e) => setEmail(e.target.value)}>
                        </input>

                        <input className='signup__input'
                            type="password"
                            placeholder='Password'
                            onChange={(e) => setPassword(e.target.value)}>
                        </input>

                        <div className='signup__checkbox'>
                            <input
                                type="checkbox"
                                id="newsletter"
                                checked={subscribeNewsletter}
                                onChange={() => setSubscribeNewsletter(!subscribeNewsletter)}
                            />
                            <label htmlFor="newsletter">Sign up for the newsletter</label>
                        </div>

                        <div className='signup__checkbox'>
                            <input
                                type="checkbox"
                                id="terms"
                                checked={acceptTerms}
                                onChange={() => setAcceptTerms(!acceptTerms)}
                            />
                            <label htmlFor="terms">I accept the terms and conditions*</label>
                        </div>

                        {buttonVisible &&
                            (<motion.div className='signup__button'
                                animate={{
                                    scale: buttonVisible ? 1 : 0.0,
                                    opacity: buttonVisible ? 1 : 0.0,
                                    x: mouseEnter ? 2 : 0
                                }}
                                onMouseEnter={e => setMouseEnter(true)}
                                onMouseLeave={e => setMouseEnter(false)}
                                onClick={handleSubmit}
                            >
                                Sign Up
                            </motion.div>)}

                        {!buttonVisible && <div className="signup__already"
                            onClick={() => { navigate('/signin') }}>
                            Already have an account
                        </div>}

                        <button type="submit" style={{ display: 'none' }}></button>

                        <div className='error__message__signup'>{error}</div>
                    </form>
                </motion.div>
            </div>
        </div>
    )
}

export default SignUp
