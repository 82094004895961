import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from './context/AuthContext';

const ProtectedRouteIceland = ({ children }) => {
  const { user } = UserAuth();

  if (!user) {
    return <Navigate to='/signup/iceland' />;
  }

 {/*if (!user.emailVerified) {
    return <Navigate to='/verify' />;
  }*/}

  return children;
}

export default ProtectedRouteIceland;
